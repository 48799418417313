 <template>
    <div class="flex items-center justify-between w-full mb-6">
      <h1 class="text-xl font-bold">
          Menu
      </h1>
    </div>
    <div class="grid grid-col-1 gap-4">

      <Loading :enabled="loading" />


      <div v-if="!loading" class="flex-row gap-3 grow min-h-full" style="min-height: calc(100vh - 170px);">

        <div v-if="!loading">
        <Icon.Plus @click="menuAddCategory(element)" />
          <draggable
            v-model="menuItems"
            group="category"
            @start="menuItemsDrag = true"
            @end="menuProductSavePositions()"
            item-key="id"
            handle=".handle"
          >
            <template #item="{ element }">
              <div class="bg-slate-200 p-4">
                <div class="p-2 flex grid-cols-3 gap-4">
                  <Icon.AlignJustify class="handle" />
                  <div class="w-full">{{ element.name }}</div>
                  <Icon.Trash class="m-auto" @click="menuRemoveCategory(element)"/>
                  <Icon.Plus @click="menuAddProduct(element)" />
                </div>
                <draggable
                  v-model="element.menu_items"
                  :group="element.name"
                  @start="menuItemsDrag = true"
                  @end="menuProductSavePositions(element.id)"
                  item-key="id"
                  handle=".handle"
                >
                  <template #item="{ element }">
                    <div
                      class="bg-slate-100 p-4 flex grid-cols-4 gap-4 shadow-md"
                    >
                      <div class="flex">
                        <Icon.AlignJustify class="handle m-auto" />
                      </div>
                      <ImageComponentAdmin
                        @click="showProductDetail(element)"
                        :image_url="element.image_url"
                        :store_id="store_id"
                        :name="element.id"
                        class="w-12 h-12"
                      />
                      <div
                        class="flex-auto"
                        @click="showProductDetail(element)"
                      >
                        {{ element.name }}<br />{{ toCurrency(element.price) }}
                        <span
                          class="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-red-200 text-red-700 rounded-3xl"
                          v-if="!element.available"
                          >indisponível</span
                        >
                      </div>
                      <div class="flex">
                        <Icon.Trash
                          class="m-auto"
                          @click="menuRemoveProduct(element)"
                        />
                      </div>
                    </div>
                  </template>
                </draggable>
              </div>
            </template>
          </draggable>

          <div class="flex justify-end">
            <button
              @click="menuTabSave"
              class="px-6 py-3 my-4 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
            >
              Gravar
            </button>
          </div>
        </div>
      </div>

  </div>


  <div
    v-if="menuPopupOpened"
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-product"
  >
    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="w-auto p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
    >
      <!--content-->
      <div>
        <Icon.X
          @click="menuClosePopup"
          class="cursor-pointer absolute top-1 right-1"
        />
        <!--body-->
        <div class="text-center gap-4 p-5 flex-auto justify-center">
          <div class="mb-4" v-if="currentProductData.id == ''">
            Novo produto
          </div>
          <div class="mb-4" v-else>Editar produto</div>
          <input
            id="name"
            name="name"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="text"
            placeholder="Nome"
            v-model="currentProductData.name"
          />
          <div
            class="text-center grid grid-cols-2 gap-4 p-5 justify-center"
          >
            <div>
                <label class="">Foto</label>
              <image-upload
                v-if="currentProductData.id != ''"
                class="h-24"
                :name="currentProductData.id"
                :product="currentProductData"
                :store_id="store_id"
                :maxSize="5"
                accept="png,jpg,jpeg"
                @file-uploaded="uploadImage"
              />
            </div>
              <div class="mb-4">
                <label for="description" class="">Descrição</label>
              <textarea
                id="description"
                name="description"
                rows="5"
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text"
                placeholder="Descrição"
                v-model="currentProductData.description"
              />
              </div>

              <div class="mb-4">
                <label for="price" class="">Preço</label>
              <input
                id="price"
                name="price"
                class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                type="text"
                placeholder="Preço"
                v-model="currentProductData.price"
              />
              </div>

              <div class="mb-4">
                <label for="integration_id" class="">Integration Id</label>

                <input
                  type="text"
                  id="integration_id"
                  v-model="currentProductData.integration_id"
                  class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
                />
              </div>

              <div class="mb-4">
                <label for="availible" class="">Em estoque?</label>

                <input
                  type="checkbox"
                  id="availible"
                  v-model="currentProductData.available"
                  true-value="1"
                  false-value="0"
                  class="relative w-[3.25rem] h-7 bg-default-200 focus:ring-0 checked:bg-none checked:!bg-primary border-2 border-transparent rounded-3xl cursor-pointer transition-colors ease-in-out duration-200 appearance-none focus:ring-transparent before:inline-block before:w-6 before:h-6 before:bg-white before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-3xl before:transform before:transition before:ease-in-out before:duration-200"
                />
              </div>
          </div>
          <div class="flex justify-end">
            <button
              @click="menuProductSave"
              class="px-6 py-3 my-4 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
            >
              Gravar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>





  <div
    v-if="menuCategoryPopupOpened"
    class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    id="modal-category"
  >
    <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
    <div
      class="w-auto p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white"
    >
      <!--content-->
      <div>
        <Icon.X
          @click="menuCategoryClosePopup"
          class="cursor-pointer absolute top-1 right-1"
        />
        <!--body-->
        <div class="text-center gap-4 p-5 flex-auto justify-center">
          <div class="mb-4" v-if="currentCategoryData.id == ''">
            Nova Categoria
          </div>
          <div class="mb-4" v-else>Editar categoria</div>
          <input
            id="name"
            name="name"
            class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50"
            type="text"
            v-model="currentCategoryData.name"
          />
          <div class="flex justify-end">
            <button
              @click="menuCategorySave"
              class="px-6 py-3 my-4 rounded-3xl bg-primary text-white capitalize transition-all hover:bg-primary"
            >
              Gravar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import * as Icon from "lucide-vue-next";
import _debounce from "lodash/debounce";
import moment from 'moment';
import draggable from "vuedraggable";
import OrderCard from "./OrderCard.vue";
import Loading from "../Loading.vue";
import FormData from "form-data";
</script>

<script>
export default {
  components: {
    'card': OrderCard,
  },
  props: {
    store_id: { type: String },
  },
  data() {
    return {
      loading: true,

      menuItems: [],
      menuItemsDrag: false,
      menuPopupOpened: false,
      menuCategoryPopupOpened: false,
      currentProduct: {},
      currentProductData: {},
      currentCategory: {},
      currentCategoryData: {},

      orders: [],
      orders_new: [],
      orders_ready: [],
      orders_accepted: [],
      orders_delivering: [],
      orders_done: [],
      order: {},
      quoteId: '',
      quotePrice: 0,
      quoteDistance: 0,
      quoteExpiration: null,
      address_zipcode: '',
      address_lat: '',
      address_lng: '',
      address_area_level_1: '',
      address_area_level_2: '',
      address_area_level_3: '',
      address_country: '',
      preparation_time: 20,
      newOrder: {},
      newOrderTemp: {},
      sugestionsList: [],
      confirmMode: false,
      finalConfirm: false,
      confirmPlace: null,
      confirmPlace2: null,
      confirmPlaceId: 0,
      confirmObs: "",
      confirmObsDefault: "",
      eater_name: "",
      eater_phone: "",
      eater_nif: "",
      address_number: "",
      appartment_number: "",
      address_number_error: "",
      newOrderModalOpened: false,
      printOrder: {
              id: "receipt_print",
              popTitle: 'Pedido #',
             beforeOpenCallback (vue) {
                //vue.printLoading = true
                //console.log('打开之前')
              },
              openCallback (vue) {
                //vue.printLoading = false
                //console.log('执行了打印')
              },
              closeCallback (vue) {
                //console.log('关闭了打印工具')
              }
            }
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const self = this;
      this.loading = true;
      self.menuItems = [];

      axios
        .get("/admin/store/" + this.store_id + "/menu")
        .then((response) => {
          self.menuItems = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showProductDetail(product) {
      this.menuPopupOpened = true;

      this.currentProduct = product;
      this.currentProductData = Object.assign({}, product);
    },
    menuAddCategory(element) {
      this.currentCategoryData = {
        id: "",
        name: "",
      };
      this.menuCategoryPopupOpened = true;
    },
    menuAddProduct(element) {
      this.currentProductData = {
        available: 1,
        description: "",
        id: "",
        menu_category_id: element.id,
        name: "",
        price: "",
        size: null,
        uom: null,
      };
      this.menuPopupOpened = true;
    },
    menuRemoveProduct(element) {
      let self = this;
      axios
        .post("/admin/store/" + this.store_id + "/menuitem/delete", {
          id: element.id,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.loadData();
        });
    },
    menuRemoveCategory(element) {
      let self = this;
      axios
        .post("/admin/store/" + this.store_id + "/menucategory/delete", {
          id: element.id,
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.loadData();
        });
    },
    menuProductSave() {
      this.currentProduct.name = this.currentProductData.name;
      this.currentProduct.description = this.currentProductData.description;
      this.currentProduct.price = this.currentProductData.price;
      this.currentProduct.available = this.currentProductData.available
        ? "1"
        : "0";

      axios
        .post("/admin/store/" + this.store_id + "/menuitem", {
          id: this.currentProductData.id,
          name: this.currentProductData.name,
          description: this.currentProductData.description,
          price: this.currentProductData.price,
          menu_category_id: this.currentProductData.menu_category_id,
          order: this.currentProductData.order,
          available: this.currentProductData.available,
        })
        .catch((error) => {
          console.log(error);
        });

      this.menuClosePopup();
    },
    menuProductSavePositions(menucategory_id) {
      const self = this;

      this.menuItems.forEach((category, categoryIndex) => {
        category.order = categoryIndex;
        category.menu_items.forEach((product, productIndex) => {
          product.order = productIndex;
        });
      });

      let data = [];

      if (menucategory_id == undefined) {
        this.menuItems.forEach((category, categoryIndex) => {
          data.push({
            id: category.id,
            name: category.name,
            order: category.order,
          });
        });

        axios
          .post("/admin/store/" + this.store_id + "/menupositions", {
            elements: data,
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            self.loadData();
          });
      } else {
        this.menuItems
          .filter((c) => c.id == menucategory_id)[0]
          .menu_items.forEach((product, productIndex) => {
            data.push({
              id: product.id,
              order: product.order,
            });
          });

        axios
          .post("/admin/store/" + this.store_id + "/menuitempositions", {
            elements: data,
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            self.loadData();
          });
      }
    },
    menuCategorySave() {
      this.currentCategory.name = this.currentCategoryData.name;

      axios
        .post("/admin/store/" + this.store_id + "/menucategory", {
          id: this.currentCategoryData.id,
          name: this.currentCategoryData.name,
        })
        .catch((error) => {
          console.log(error);
        });

      this.menuCategoryClosePopup();
    },

    uploadImage(file, name, product_id, dom) {
      let data = new FormData();
      data.append("name", name);
      data.append("product_id", product_id);
      data.append("image", file);

      axios
        .post("/admin/images/upload/" + this.store_id, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .catch((error) => {
          console.log(error);
        })
        .finally((error) => {
          if (dom) {
            var dt = new Date();
            if (dom.src.length >= 0) {
              dom.src = dom.src + "?" + dt.getTime();
            }
          }
        });
    },
    menuCategoryClosePopup() {
      this.menuCategoryPopupOpened = false;
      this.loadData();
    },
    menuClosePopup() {
      this.menuPopupOpened = false;
      this.loadData();
    },
    menuTabSave() {
      this.menuItems.forEach((category, categoryIndex) => {
        category.order = categoryIndex;
        category.menu_items.forEach((product, productIndex) => {
          product.order = productIndex;
        });
      });
    },

    orderStatus(value){
      switch (value) {
        case "NEW":
          return "Novo";
        case "ACCEPTED":
          return "Aceito";
        case "READY":
          return "Pronto";
        case "ON_DELIVERY":
          return "Em Entrega";
        case "FINISHED":
        case "DELIVERED":
          return "Finalizado";
        case "CANCELED":
          return "Cancelado";
        case "REJECTED":
          return "Rejeitado";
        case "BACK_TO_NEW":
          return "Devolvido";
        case "BACK_TO_ACCEPT":
          return "Devolvido";
        default:
          return value;
      }
    },

    toCurrency(value, currency = "EUR") {
      value = parseFloat(value, 2);
      if (typeof value !== "number") {
        return value;
      }

      var formatter;

      if(currency == ''){
        formatter = new Intl.NumberFormat("pt-PT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        formatter = new Intl.NumberFormat("pt-PT", {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }

      return formatter.format(value);
    },
  },
};
</script>
