<template>
    <div
        v-if="product.qtd > 1"
        class="h-8 select-none inline-flex items-center justify-center rounded-full bg-white/[.9] p-1 text-center font-medium shadow transition-all duration-500"
    >
        <a v-on:click="removeQty(-1)" class="px-3 cursor-pointer">
        <Icon.Minus :size="18" />
        </a>
        <span>{{ product.qtd }}</span>
        <a v-on:click="addQty(1)" class="px-3 cursor-pointer">
        <Icon.Plus :size="18" />
        </a>
    </div>

    <div
        v-if="product.qtd == 1"
        class="h-8 select-none inline-flex items-center justify-center rounded-full bg-white/[.9] p-1 text-center font-medium shadow transition-all duration-500"
    >
        <a v-on:click="removeQty(-1)" class="px-3 cursor-pointer">
        <Icon.Trash :size="18" />
        </a>
        <span>{{ product.qtd }}</span>
        <a v-on:click="addQty(1)" class="px-3 cursor-pointer">
        <Icon.Plus :size="18" />
        </a>
    </div>

    <div
        v-if="product.qtd == 0"
        class="h-8 w-10 select-none inline-flex items-center justify-center rounded-full bg-white/[.9] p-1 text-center font-medium shadow transition-all duration-500"
    >
        <a v-on:click="addQty(1)" class="p-1 cursor-pointer">
        <Icon.Plus :size="18" />
        </a>
    </div>
  </template>

<script setup>
import * as Icon from "lucide-vue-next";
</script>

  <script>
  export default {
    props: {
      product: { type: Object },
      add: { type: Function },
      remove: { type: Function },
    },
    data() {
      return {
      };
    },
    mounted() {
    },
    created() {
    },

    methods: {
      addQty(value){
        this.add(this.product, value);
      },
      removeQty(value){
        this.remove(this.product, value);
      }
    },
  };
  </script>
